import React, { createContext, useContext, useState } from 'react';

const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState([]);

  const addToBasket = (item) => {
    setBasket((prevBasket) => {
      const existingItemIndex = prevBasket.findIndex((i) => i.item === item.item);
      if (existingItemIndex >= 0) {
        const newBasket = [...prevBasket];
        newBasket[existingItemIndex].quantity += 1;
        return newBasket;
      }
      return [...prevBasket, { ...item, quantity: 1, notes: '' }];
    });
  };

  const removeFromBasket = (index) => {
    setBasket((prevBasket) => {
      const newBasket = [...prevBasket];
      newBasket.splice(index, 1);
      return newBasket;
    });
  };

  const updateNotes = (index, notes) => {
    setBasket((prevBasket) => {
      const newBasket = [...prevBasket];
      newBasket[index].notes = notes;
      return newBasket;
    });
  };

  const clearBasket = () => {
    setBasket([]);
  };

  return (
    <BasketContext.Provider value={{ basket, addToBasket, removeFromBasket, updateNotes, clearBasket }}>
      {children}
    </BasketContext.Provider>
  );
};

export const useBasket = () => useContext(BasketContext);
