import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import './css/AdminLogs.css';

const AdminLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loadMoreCount, setLoadMoreCount] = useState(10);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('1D');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editingLog, setEditingLog] = useState(null);

  useEffect(() => {
    fetchLogs();
  }, [selectedTimeFrame, startDate, endDate]);

  const fetchLogs = async () => {
    try {
      const token = localStorage.getItem('adminSessionToken');
      if (!token) {
        throw new Error('No admin session token found');
      }

      const response = await axios.get('/api/admin/logs', {
        headers: { Authorization: `Bearer ${token}` },
        params: { timeFrame: selectedTimeFrame, startDate, endDate },
      });
      console.log('Fetched logs:', response.data); // Debug logging
      setLogs(response.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const handleEdit = (log) => {
    setEditingLog(log);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this log?')) {
      try {
        const token = localStorage.getItem('adminSessionToken');
        await axios.delete(`/api/admin/logs/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        fetchLogs();
      } catch (error) {
        console.error('Error deleting log:', error);
      }
    }
  };

  const calculateTotalAmount = () => {
    return logs.reduce((total, log) => {
      if (!log.order) return total;
      return total + log.order.reduce((orderTotal, item) => {
        const price = parseFloat(item.item.split('-')[1].trim().split(' ')[0]);
        return orderTotal + (price * item.quantity);
      }, 0);
    }, 0);
  };

  const calculateTotalItems = () => {
    return logs.reduce((total, log) => {
      if (!log.order) return total;
      return total + log.order.reduce((orderTotal, item) => orderTotal + item.quantity, 0);
    }, 0);
  };

  const calculateHotUmbrellas = () => {
    const umbrellaOrders = {};
    logs.forEach(log => {
      if (!log.order) return;
      if (!umbrellaOrders[log.table]) {
        umbrellaOrders[log.table] = 0;
      }
      umbrellaOrders[log.table] += log.order.reduce((total, item) => total + item.quantity, 0);
    });
    return Object.entries(umbrellaOrders).sort((a, b) => b[1] - a[1]);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('adminSessionToken');
      await axios.put(`/api/admin/logs/${editingLog._id}`, editingLog, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEditingLog(null);
      fetchLogs();
    } catch (error) {
      console.error('Error saving log:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('order-')) {
      const index = parseInt(name.split('-')[1], 10);
      const key = name.split('-')[2];
      const updatedOrder = editingLog.order.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      );
      setEditingLog({ ...editingLog, order: updatedOrder });
    } else {
      setEditingLog({ ...editingLog, [name]: value });
    }
  };

  const filterLogs = () => {
    return logs.slice(0, loadMoreCount);
  };

  return (
    <div className="admin-logs-container">
      <h1>Admin Logs</h1>
      <div className="filter-buttons">
        <span>Last:</span>
        <button onClick={() => setSelectedTimeFrame('1D')}>1D</button>
        <button onClick={() => setSelectedTimeFrame('2D')}>2D</button>
        <button onClick={() => setSelectedTimeFrame('3D')}>3D</button>
        <button onClick={() => setSelectedTimeFrame('1W')}>1W</button>
        <button onClick={() => setSelectedTimeFrame('2W')}>2W</button>
        <button onClick={() => setSelectedTimeFrame('3W')}>3W</button>
        <button onClick={() => setSelectedTimeFrame('1M')}>1M</button>
        <button onClick={() => setSelectedTimeFrame('2M')}>2M</button>
        <button onClick={() => setSelectedTimeFrame('3M')}>3M</button>
        <span>Custom Range:</span>
        <input type="date" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <input type="date" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <button onClick={() => fetchLogs()}>Apply</button>
      </div>
      <div className="statistics">
        <h2>Statistics</h2>
        <p>Total Amount: {calculateTotalAmount()} LEK</p>
        <p>Total Items: {calculateTotalItems()}</p>
        <h3>Hot Umbrellas</h3>
        <ul>
          {calculateHotUmbrellas().map(([table, count]) => (
            <li key={table}>{table}: {count} items</li>
          ))}
        </ul>
      </div>
      <div className="logs">
        {filterLogs().map(log => (
          <div key={log._id} className="log-item">
            {editingLog && editingLog._id === log._id ? (
              <div>
                <input type="text" name="table" value={editingLog.table} onChange={handleChange} />
                <input type="text" name="amount" value={editingLog.amount} onChange={handleChange} />
                <input type="text" name="date" value={editingLog.date} onChange={handleChange} />
                <input type="text" name="time" value={editingLog.time} onChange={handleChange} />
                <ul>
                  {editingLog.order.map((item, index) => (
                    <li key={index}>
                      <input
                        type="text"
                        name={`order-${index}-item`}
                        value={item.item}
                        onChange={handleChange}
                      />
                      <input
                        type="number"
                        name={`order-${index}-quantity`}
                        value={item.quantity}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        name={`order-${index}-notes`}
                        value={item.notes}
                        onChange={handleChange}
                      />
                    </li>
                  ))}
                </ul>
                <button onClick={handleSave}>Save</button>
                <button onClick={() => setEditingLog(null)}>Cancel</button>
              </div>
            ) : (
              <div>
                <p>Table: {log.table}</p>
                <p>Time: {moment(log.createdAt).format('LLL')}</p>
                <p>Order:</p>
                <ul>
                  {log.order && log.order.map((item, index) => (
                    <li key={index}>
                      {item.item} - {item.quantity} {item.notes ? `/ Notes: ${item.notes}` : ''}
                    </li>
                  ))}
                </ul>
                <button onClick={() => handleEdit(log)}>Edit</button>
                <button onClick={() => handleDelete(log._id)}>Delete</button>
              </div>
            )}
          </div>
        ))}
      </div>
      {logs.length > loadMoreCount && (
        <button onClick={() => setLoadMoreCount(loadMoreCount + 10)}>Load More</button>
      )}
    </div>
  );
};

export default AdminLogs;
