import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

const languages = {
  en: {
    menu: 'Menu',
    table: 'Table',
    basket: 'Basket',
    submitOrder: 'Submit Order',
    orderSubmitted: 'Order Submitted!',
    orderSubmittedMessage: 'Your order has been successfully submitted.',
    close: 'Close',
    orderCompleted: 'Order Completed',
    orderDetails: 'Order Details',
    Kafe: 'Coffee',
    'Pije Freskuese': 'Refreshing Drinks',
    Birra: 'Beers',
    Kokteje: 'Cocktails',
    Akullore: 'Ice Cream',
    'Espresso - 100 LEK': 'Espresso - 100 LEK',
    'Espresso Dopio - 150 LEK': 'Double Espresso - 150 LEK',
    'Makiato - 120 LEK': 'Macchiato - 120 LEK',
    'Cappuccino - 200 LEK': 'Cappuccino - 200 LEK',
    'Redbull - 400 LEK': 'Redbull - 400 LEK',
    'Ujë - 100 LEK': 'Water - 100 LEK',
    'Ujë me Gaz - 100 LEK': 'Sparkling Water - 100 LEK',
    'Nescafe i Ftohtë - 250 LEK': 'Iced Nescafe - 250 LEK',
    'Fanta Exotic - 200 LEK': 'Fanta Exotic - 200 LEK',
    'Fanta Orange - 200 LEK': 'Fanta Orange - 200 LEK',
    'Pepsi - 200 LEK': 'Pepsi - 200 LEK',
    'Çaj i Ftohtë Pjeshke - 200 LEK': 'Cold Tea Peach - 200 LEK',
    'Çaj i Ftohtë Limoni - 200 LEK': 'Cold Tea Lemon - 200 LEK',
    'Bravo Pjeshkë - 200 LEK': 'Bravo Peach - 200 LEK',
    'Bravo Mollë - 200 LEK': 'Bravo Apple - 200 LEK',
    'Bravo Luleshtrydhe - 200 LEK': 'Bravo Strawberry - 200 LEK',
    'Lemon Soda - 200 LEK': 'Lemon Soda - 200 LEK',
    'Orange Soda - 200 LEK': 'Orange Soda - 200 LEK',
    'Schweps - 200 LEK': 'Schweps - 200 LEK',
    'Korca - 300 LEK': 'Korca - 300 LEK',
    'Elbar - 300 LEK': 'Elbar - 300 LEK',
    'Peroni - 300 LEK': 'Peroni - 300 LEK',
    'Heineken - 400 LEK': 'Heineken - 400 LEK',
    'Corona - 500 LEK': 'Corona - 500 LEK',
    'Bavaria no alc - 300 LEK': 'Bavaria no alc - 300 LEK',
    'Gintonic - 600 LEK': 'Gintonic - 600 LEK',
    'Mojito - 600 LEK': 'Mojito - 600 LEK',
    'Gin ice - 500 LEK': 'Gin ice - 500 LEK',
    'Vodka sour - 600 LEK': 'Vodka sour - 600 LEK',
    'Disarono sour - 700 LEK': 'Disarono sour - 700 LEK',
    'Shots - 200 LEK': 'Shots - 200 LEK',
    'Analkolik - 500 LEK': 'Analkolik - 500 LEK',
    'Magnum Choco - 350 LEK': 'Magnum Choco - 350 LEK',
    'Magnum Vanilla - 350 LEK': 'Magnum Vanilla - 350 LEK',
    'Rodeo Choco - 150 LEK': 'Rodeo Choco - 150 LEK',
    'Rodeo Vanilje - 150 LEK': 'Rodeo Vanilla - 150 LEK',
    'Rodeo Cookies - 150 LEK': 'Rodeo Cookies - 150 LEK',
    'Milka Classic - 300 LEK': 'Milka Classic - 300 LEK',
    'Milka Hazel nuts - 300 LEK': 'Milka Hazel nuts - 300 LEK',
    'Milka Strawbarry - 300 LEK': 'Milka Strawberry - 300 LEK',
    'Oreo Cone - 300 LEK': 'Oreo Cone - 300 LEK',
    'Oreo Sandwich - 300 LEK': 'Oreo Sandwich - 300 LEK',
    'Kitkat Cocoa - 250 LEK': 'Kitkat Cocoa - 250 LEK',
    notes: 'Notes',
    noTokenMessage: 'Welcome to Plazhi i Gjeneralit Beach Bar! Please find a place to sit and scan the QR code to order, or visit the beach bar in person.',
  },
  al: {
    menu: 'Menu',
    table: 'Tavolina',
    basket: 'Shporta',
    submitOrder: 'Dërgo Porosinë',
    orderSubmitted: 'Porosia u dërgua!',
    orderSubmittedMessage: 'Porosia juaj është dërguar me sukses.',
    close: 'Mbyll',
    orderCompleted: 'Porosia e Kryer',
    orderDetails: 'Detajet e Porosisë',
    Kafe: 'Kafe',
    'Pije Freskuese': 'Pije Freskuese',
    Birra: 'Birra',
    Kokteje: 'Kokteje',
    Akullore: 'Akullore',
    'Espresso - 100 LEK': 'Espresso - 100 LEK',
    'Espresso Dopio - 150 LEK': 'Espresso Dopio - 150 LEK',
    'Makiato - 120 LEK': 'Makiato - 120 LEK',
    'Cappuccino - 200 LEK': 'Cappuccino - 200 LEK',
    'Redbull - 400 LEK': 'Redbull - 400 LEK',
    'Ujë - 100 LEK': 'Ujë - 100 LEK',
    'Ujë me Gaz - 100 LEK': 'Ujë me Gaz - 100 LEK',
    'Nescafe i Ftohtë - 250 LEK': 'Nescafe i Ftohtë - 250 LEK',
    'Fanta Exotic - 200 LEK': 'Fanta Exotic - 200 LEK',
    'Fanta Orange - 200 LEK': 'Fanta Orange - 200 LEK',
    'Pepsi - 200 LEK': 'Pepsi - 200 LEK',
    'Çaj i Ftohtë Pjeshke - 200 LEK': 'Çaj i Ftohtë Pjeshke - 200 LEK',
    'Çaj i Ftohtë Limoni - 200 LEK': 'Çaj i Ftohtë Limoni - 200 LEK',
    'Bravo Pjeshkë - 200 LEK': 'Bravo Pjeshkë - 200 LEK',
    'Bravo Mollë - 200 LEK': 'Bravo Mollë - 200 LEK',
    'Bravo Luleshtrydhe - 200 LEK': 'Bravo Luleshtrydhe - 200 LEK',
    'Lemon Soda - 200 LEK': 'Lemon Soda - 200 LEK',
    'Orange Soda - 200 LEK': 'Orange Soda - 200 LEK',
    'Schweps - 200 LEK': 'Schweps - 200 LEK',
    'Korca - 300 LEK': 'Korca - 300 LEK',
    'Elbar - 300 LEK': 'Elbar - 300 LEK',
    'Peroni - 300 LEK': 'Peroni - 300 LEK',
    'Heineken - 400 LEK': 'Heineken - 400 LEK',
    'Corona - 500 LEK': 'Corona - 500 LEK',
    'Bavaria no alc - 300 LEK': 'Bavaria pa alc - 300 LEK',
    'Gintonic - 600 LEK': 'Gintonic - 600 LEK',
    'Mojito - 600 LEK': 'Mojito - 600 LEK',
    'Gin ice - 500 LEK': 'Gin ice - 500 LEK',
    'Vodka sour - 600 LEK': 'Vodka sour - 600 LEK',
    'Disarono sour - 700 LEK': 'Disarono sour - 700 LEK',
    'Shots - 200 LEK': 'Shots - 200 LEK',
    'Analkolik - 500 LEK': 'Analkolik - 500 LEK',
    'Magnum Choco - 350 LEK': 'Magnum Choco - 350 LEK',
    'Magnum Vanilla - 350 LEK': 'Magnum Vanilje - 350 LEK',
    'Rodeo Choco - 150 LEK': 'Rodeo çokollatë - 150 LEK',
    'Rodeo Vanilje - 150 LEK': 'Rodeo Vanilje - 150 LEK',
    'Rodeo Cookies - 150 LEK': 'Rodeo Biskote - 150 LEK',
    'Milka Classic - 300 LEK': 'Milka Klasike - 300 LEK',
    'Milka Hazel nuts - 300 LEK': 'Milka Lajthi - 300 LEK',
    'Milka Strawbarry - 300 LEK': 'Milka Luleshtrydhe - 300 LEK',
    'Oreo Cone - 300 LEK': 'Oreo Kaush - 300 LEK',
    'Oreo Sandwich - 300 LEK': 'Oreo Sanduiç - 300 LEK',
    'Kitkat Cocoa - 250 LEK': 'Kitkat Kakao - 250 LEK',
    notes: 'Shënime',
    noTokenMessage: 'Mirë se vini në Plazhin e Gjeneralit Beach Bar! Ju lutemi gjeni një vend për të qëndruar dhe skanoni kodin QR për të porositur, ose vizitoni plazhin e barit personalisht.',
  },
  it: {
    menu: 'Menu',
    table: 'Tavolo',
    basket: 'Carrello',
    submitOrder: 'Invia Ordine',
    orderSubmitted: 'Ordine Inviato!',
    orderSubmittedMessage: 'Il tuo ordine è stato inviato con successo.',
    close: 'Chiudi',
    orderCompleted: 'Ordine Completato',
    orderDetails: 'Dettagli dell\'Ordine',
    Kafe: 'Caffè',
    'Pije Freskuese': 'Bevande Rinfrescanti',
    Birra: 'Birre',
    Kokteje: 'Cocktail',
    Akullore: 'Gelato',
    'Espresso - 100 LEK': 'Espresso - 100 LEK',
    'Espresso Dopio - 150 LEK': 'Espresso Doppio - 150 LEK',
    'Makiato - 120 LEK': 'Macchiato - 120 LEK',
    'Cappuccino - 200 LEK': 'Cappuccino - 200 LEK',
    'Redbull - 400 LEK': 'Redbull - 400 LEK',
    'Ujë - 100 LEK': 'Acqua - 100 LEK',
    'Ujë me Gaz - 100 LEK': 'Acqua Frizzante - 100 LEK',
    'Nescafe i Ftohtë - 250 LEK': 'Nescafe Freddo - 250 LEK',
    'Fanta Exotic - 200 LEK': 'Fanta Exotic - 200 LEK',
    'Fanta Orange - 200 LEK': 'Fanta Orange - 200 LEK',
    'Pepsi - 200 LEK': 'Pepsi - 200 LEK',
    'Çaj i Ftohtë Pjeshke - 200 LEK': 'Tè Freddo alla Pesca - 200 LEK',
    'Çaj i Ftohtë Limoni - 200 LEK': 'Tè Freddo al Limone - 200 LEK',
    'Bravo Pjeshkë - 200 LEK': 'Bravo Pesca - 200 LEK',
    'Bravo Mollë - 200 LEK': 'Bravo Mela - 200 LEK',
    'Bravo Luleshtrydhe - 200 LEK': 'Bravo Fragola - 200 LEK',
    'Lemon Soda - 200 LEK': 'Lemon Soda - 200 LEK',
    'Orange Soda - 200 LEK': 'Orange Soda - 200 LEK',
    'Schweps - 200 LEK': 'Schweps - 200 LEK',
    'Korca - 300 LEK': 'Korca - 300 LEK',
    'Elbar - 300 LEK': 'Elbar - 300 LEK',
    'Peroni - 300 LEK': 'Peroni - 300 LEK',
    'Heineken - 400 LEK': 'Heineken - 400 LEK',
    'Corona - 500 LEK': 'Corona - 500 LEK',
    'Bavaria no alc - 300 LEK': 'Bavaria no alc - 300 LEK',
    'Gintonic - 600 LEK': 'Gintonic - 600 LEK',
    'Mojito - 600 LEK': 'Mojito - 600 LEK',
    'Gin ice - 500 LEK': 'Gin ice - 500 LEK',
    'Vodka sour - 600 LEK': 'Vodka sour - 600 LEK',
    'Disarono sour - 700 LEK': 'Disarono sour - 700 LEK',
    'Shots - 200 LEK': 'Shots - 200 LEK',
    'Analkolik - 500 LEK': 'Analkolik - 500 LEK',
    'Magnum Choco - 350 LEK': 'Magnum Choco - 350 LEK',
    'Magnum Vanilla - 350 LEK': 'Magnum Vaniglia - 350 LEK',
    'Rodeo Choco - 150 LEK': 'Rodeo Cioccolato - 150 LEK',
    'Rodeo Vanilje - 150 LEK': 'Rodeo Vaniglia - 150 LEK',
    'Rodeo Cookies - 150 LEK': 'Rodeo Biscotto - 150 LEK',
    'Milka Classic - 300 LEK': 'Milka Classico - 300 LEK',
    'Milka Hazel nuts - 300 LEK': 'Milka Nocciole - 300 LEK',
    'Milka Strawbarry - 300 LEK': 'Milka Fragola - 300 LEK',
    'Oreo Cone - 300 LEK': 'Oreo Cono - 300 LEK',
    'Oreo Sandwich - 300 LEK': 'Oreo Sandwich - 300 LEK',
    'Kitkat Cocoa - 250 LEK': 'Kitkat Cacao - 250 LEK',
    notes: 'Note',
    noTokenMessage: 'Benvenuti al Plazhi i Gjeneralit Beach Bar! Trova un posto a sedere e scansiona il codice QR per ordinare, oppure visita il beach bar di persona.',
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('al');

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, translations: languages[language] }}>
      {children}
    </LanguageContext.Provider>
  );
};
