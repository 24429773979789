import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import TableView from './TableView';
import MenuView from './MenuView';
import AdminLogs from './AdminLogs';
import { BasketProvider } from './BasketContext';
import { LanguageProvider } from './contexts/LanguageContext';

const App = () => {
  return (
    <LanguageProvider>
      <BasketProvider>
        <Router>
          <Routes>
            <Route path="/admin/Table" element={<TableView />} />
            <Route path="/menu/view" element={<MenuView />} />
            <Route path="/admin/logs" element={<AdminLogs />} />
            <Route path="/" element={<MenuView />} />
          </Routes>
        </Router>
      </BasketProvider>
    </LanguageProvider>
  );
};

export default App;
