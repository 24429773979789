import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useBasket } from './BasketContext';
import { useLanguage } from './contexts/LanguageContext';
import LanguageSwitcher from './components/LanguageSwitcher';
import './css/MenuView.css';
import './css/Modal.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const categories = {
  Kafe: ['Espresso - 100 LEK', 'Espresso Dopio - 150 LEK', 'Makiato - 120 LEK', 'Cappuccino - 200 LEK'],
  'Pije Freskuese': [
    'Redbull - 400 LEK',
    'Ujë - 100 LEK',
    'Ujë me Gaz - 100 LEK',
    'Nescafe i Ftohtë - 250 LEK',
    'Fanta Exotic - 200 LEK',
    'Fanta Orange - 200 LEK',
    'Pepsi - 200 LEK',
    'Çaj i Ftohtë Pjeshke - 200 LEK',
    'Çaj i Ftohtë Limoni - 200 LEK',
    'Bravo Pjeshkë - 200 LEK',
    'Bravo Mollë - 200 LEK',
    'Bravo Luleshtrydhe - 200 LEK',
    'Lemon Soda - 200 LEK',
    'Orange Soda - 200 LEK',
    'Schweps - 200 LEK'
  ],
  Birra: ['Korca - 300 LEK', 'Elbar - 300 LEK', 'Peroni - 300 LEK', 'Heineken - 400 LEK', 'Corona - 500 LEK', 'Bavaria no alc - 300 LEK'],
  Kokteje: ['Gintonic - 600 LEK', 'Mojito - 600 LEK', 'Gin ice - 500 LEK', 'Vodka sour - 600 LEK', 'Disarono sour - 700 LEK', 'Shots - 200 LEK', 'Analkolik - 500 LEK'],
  Akullore: [
    'Magnum Choco - 350 LEK',
    'Magnum Vanilla - 350 LEK',
    'Rodeo Choco - 150 LEK',
    'Rodeo Vanilje - 150 LEK',
    'Rodeo Cookies - 150 LEK',
    'Milka Classic - 300 LEK',
    'Milka Hazel nuts - 300 LEK',
    'Milka Strawbarry - 300 LEK',
    'Oreo Cone - 300 LEK',
    'Oreo Sandwich - 300 LEK',
    'Kitkat Cocoa - 250 LEK'
  ]
};

const MenuView = () => {
  const [token, setToken] = useState(null);
  const [tableNumber, setTableNumber] = useState(null);
  const [sessionValid, setSessionValid] = useState(false);
  const { basket, addToBasket, removeFromBasket, updateNotes, clearBasket } = useBasket();
  const query = useQuery();
  const { translations } = useLanguage();

  useEffect(() => {
    const tableToken = query.get('sessionToken');
    if (tableToken) {
      localStorage.setItem('sessionToken', tableToken);
      setToken(tableToken);
      validateSessionToken(tableToken);
    }
  }, [query]);

  const validateSessionToken = async (token) => {
    try {
      const response = await axios.get(`/api/table?sessionToken=${token}`);
      setTableNumber(response.data.tableNumber);
      setSessionValid(true);
    } catch (error) {
      console.error('Session token validation failed:', error);
      handleSessionExpiration();
    }
  };

  const handleSessionExpiration = () => {
    alert('Your session has expired. Please rescan the QR code to continue.');
    localStorage.removeItem('sessionToken');
    setSessionValid(false);
  };

  const handleAddToBasket = (item) => {
    addToBasket({ item });
  };

  const [openCategory, setOpenCategory] = useState('');

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? '' : category);
  };

  const handleSubmitOrder = async () => {
    try {
      const orderData = basket.map(item => ({
        item: item.item,
        quantity: item.quantity,
        notes: item.notes
      }));

      await axios.post('/api/orders', { sessionToken: token, order: orderData }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      clearBasket();
      alert(translations.orderSubmittedMessage);
    } catch (error) {
      console.error('Error submitting order:', error.response.data);
      if (error.response.status === 401) {
        handleSessionExpiration();
      }
    }
  };

  const renderCategories = () => {
    return Object.keys(categories).map((category) => (
      <div key={category} className="category">
        <h3 onClick={() => toggleCategory(category)}>
          {translations[category]} <span className="arrow">{openCategory === category ? '▲' : '▼'}</span>
        </h3>
        {openCategory === category && (
          <div className="items">
            {categories[category].map((item) => (
              <button
                key={item}
                onClick={sessionValid ? () => handleAddToBasket(item) : null}
                className={sessionValid ? '' : 'disabled'}
              >
                {translations[item]}
              </button>
            ))}
          </div>
        )}
      </div>
    ));
  };

  if (!sessionValid) {
    return (
      <div className="menu-container">
        <LanguageSwitcher />
        <h1>{translations.menu}</h1>
        <p>{translations.welcomeMessage}</p>
        <p>{translations.noTokenMessage}</p>
        <p>{translations.pleaseRescanQRCode}</p>
        <div className="categories">
          {renderCategories()}
        </div>
      </div>
    );
  }

  return (
    <div className="menu-container">
      <LanguageSwitcher />
      <h1>{translations.menu}</h1>
      <p>{translations.welcomeMessage}</p>
      {token && tableNumber !== null && <h2>{translations.table}: {tableNumber}</h2>}
      <div className="categories">
        {renderCategories()}
      </div>
      {basket.length > 0 && (
        <>
          <h3>{translations.basket}</h3>
          <div className="basket">
            {basket.map((item, index) => (
              <div key={index} className="basket-item">
                <span>{translations[item.item]} x {item.quantity}</span>
                <input
                  type="text"
                  placeholder="Note..."
                  value={item.notes}
                  onChange={(e) => updateNotes(index, e.target.value)}
                />
                <button onClick={() => removeFromBasket(index)} className="remove-item">X</button>
              </div>
            ))}
          </div>
          <button onClick={handleSubmitOrder} className="submit-order">{translations.submitOrder}</button>
        </>
      )}
    </div>
  );
};

export default MenuView;
